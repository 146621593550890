<template>
  <div id="organization">
    <div class="subheader subheader-transparent" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            {{ $t("menu.controller.orgchart.title") }}
          </h5>
          <!--end::Title-->
        </div>
      </div>
    </div>
    <box-tabs :tabs="tabs" @changeTab="select" small />
    <b-card
      no-body
      class="organization-card card-custom"
      :v-if="isOrganizationLoaded"
    >
      <div class="card-header">
        <div class="card-title"></div>
        <div class="card-toolbar">
          <b-button
            tag="a"
            variant="light-success"
            size="sm"
            class="btn btn-icon mr-2 svg-icon"
            @click="$refs.scaleTree.zoomOut()"
          >
            <i class="fas fa-search-minus" />
          </b-button>
          <b-button
            tag="a"
            variant="light-success"
            size="sm"
            class="btn btn-icon mr-2"
            @click="$refs.scaleTree.zoomIn()"
          >
            <i class="fas fa-search-plus" />
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <vue-tree
          style="width: 100%; height: 600px"
          ref="scaleTree"
          v-if="isOrganizationLoaded"
          :dataset="chartData"
          :config="treeConfig"
          linkStyle="straight"
        >
          <template v-slot:node="{ node, collapsed }">
            <div class="node-template">
              <div
                :class="
                  `d-flex mx-6 flex-fill justify-items-around font-weight-boldest py-2 ${getClassForNode(
                    node,
                    true
                  )}`
                "
                style="border-radius: 30px;"
              >
                <div
                  class="node-score font-size-h4 pl-3 min-w-65px mr-auto align-self-center"
                >
                  {{ getValueForNode(node) }}
                  <span class="font-size-xs" v-if="options.base !== 100">
                    &nbsp;/ {{ options.base }}
                  </span>
                </div>
                <div
                  class="node-title font-size-h5 min-w-100px align-self-center"
                  :title="node.name"
                  :disabled="
                    node.name.length === formatName(node.name).length
                      ? true
                      : false
                  "
                  v-b-tooltip.hover
                >
                  {{ formatName(node.name) }}
                </div>
                <div
                  class="node-collapsed align-self-center pr-3 svg-icon svg-icon-dark-50"
                >
                  <simple-svg
                    :src="require('@/assets/images/icons/angle-up.svg')"
                    v-if="!collapsed && node.hasChildren"
                  />
                  <simple-svg
                    :src="require('@/assets/images/icons/angle-down.svg')"
                    v-if="collapsed && node.hasChildren"
                  />
                </div>
              </div>
            </div>
          </template>
        </vue-tree>
      </div>
    </b-card>
  </div>
</template>

<script>
import VueTree from "@ssthouse/vue-tree-chart";
import BoxTabs from "@/components/menu/BoxTabs.component";
import { SimpleSVG } from "vue-simple-svg";

import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  getColorByValueAndBase,
  getClassByValueAndBase
} from "@/utils/helpers";

export default {
  name: "Organization",
  data() {
    return {
      selected: "",
      chartData: {},
      options: {
        classType: "r",
        survey: "SURVEY_KYMATIO_GRI_GLOBAL",
        dimensionGroup: "DIMENSION_GROUP_RISK",
        dimension: "DIMENSION_ELEMENT_GENERAL_RISK",
        base: 6
      },
      treeConfig: { nodeWidth: 220, nodeHeight: 50, levelHeight: 100 }
    };
  },
  components: {
    VueTree,
    BoxTabs,
    "simple-svg": SimpleSVG
  },
  methods: {
    ...mapActions("Organization", ["fetchOrganization"]),
    select(tab) {
      this.selected = tab;
    },
    formatName(name, limit = 3) {
      let words = _.words(name);
      let result = name;
      if (words.length > limit) {
        result = _.join(_.slice(words, 0, limit), " ") + "...";
      }
      return result;
    },
    getInitials(name) {
      var initials = "";
      _.each(_.words(name), word => {
        initials = initials + word.substring(0, 1);
      });
      return initials;
    },
    getValueForNode(node) {
      var value = this.getProfileById(
        node.id,
        this.options.survey,
        this.options.dimensionGroup,
        this.options.dimension
      );
      var percentage = this.options.base === 100;
      return value !== false
        ? percentage
          ? this.$n(value / 100, "percent")
          : this.$n(value, { maximumFractionDigits: 1 })
        : "?";
    },
    getBgForNode(node) {
      var value = this.getProfileById(
        node.id,
        this.options.survey,
        this.options.dimensionGroup,
        this.options.dimension
      );
      return getColorByValueAndBase(value, this.options.base, true);
    },
    getClassForNode(node, all = false) {
      var value = this.getProfileById(
        node.id,
        this.options.survey,
        this.options.dimensionGroup,
        this.options.dimension
      );
      return getClassByValueAndBase(value, this.options.base, true, all);
    },
    getColorForSVG(node) {
      var value = this.getProfileById(
        node.id,
        this.options.survey,
        this.options.dimensionGroup,
        this.options.dimension
      );
      return getColorByValueAndBase(value, this.options.base, true);
    }
  },
  computed: {
    ...mapGetters("Organization", [
      "getChartData",
      "isOrganizationLoaded",
      "getProfileById"
    ]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Auth", ["hasModule"]),
    tabs() {
      var result = [
        {
          id: "risk",
          title: this.$t("organization.tabs.risk.title"),
          image: "chart-pie.svg",
          type: "r",
          survey: "SURVEY_KYMATIO_GRI_GLOBAL",
          dimensionGroup: "DIMENSION_GROUP_RISK",
          dimension: "DIMENSION_ELEMENT_GENERAL_RISK",
          base: 6,
          service: "risk"
        },
        {
          id: "hazard",
          title: this.$t("organization.tabs.hazard.title"),
          image: "warning-triangle.svg",
          type: "i",
          survey: "SURVEY_KYMATIO_GRI_GLOBAL",
          dimensionGroup: "DIMENSION_GROUP_HAZARD",
          dimension: "DIMENSION_ELEMENT_GENERAL_HAZARD",
          base: 3,
          service: "impact"
        },
        {
          id: "awareness",
          title: this.$t("organization.tabs.awareness.title"),
          image: "shield-protected.svg",
          type: "cs",
          survey: "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          dimensionGroup: "DIMENSION_GROUP_CIBERSECURITY",
          dimension: "DIMENSION_ELEMENT_CIBER_RESULTS",
          base: 10,
          service: "awareness"
        },
        {
          id: "wellbeing",
          title: this.$t("organization.tabs.wellbeing.title"),
          image: "diagnostics.svg",
          type: "cl",
          survey: "SURVEY_KYMATIO_CLIMATE_GLOBAL",
          dimensionGroup: "DIMENSION_GROUP_WORKING",
          dimension: "DIMENSION_ELEMENT_CLIMATE_LEVEL",
          base: 10,
          service: "wellbeing"
        },
        {
          id: "progress",
          title: this.$t("organization.tabs.punctuation.title"),
          image: "battery-half.svg",
          type: "c",
          survey: "SURVEY_KYMATIO_SCORE_GLOBAL",
          dimensionGroup: "DIMENSION_GROUP_SCORING",
          dimension: "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
          base: 10
        }
      ];
      return _.filter(result, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isOrganizationLoaded) {
      this.fetchOrganization();
    } else if (this.isOrganizationLoaded) {
      this.chartData = this.getChartData;
      this.select(this.tabs[0].id);
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value) {
        this.fetchOrganization();
      }
    },
    isOrganizationLoaded(value) {
      if (value === true) {
        this.chartData = this.getChartData;
        this.select(this.tabs[0].id);
      }
    },
    selected(value) {
      var mod = _.find(this.tabs, tab => {
        return tab.id === value;
      });
      this.options.classType = mod.type;
      this.options.survey = mod.survey;
      this.options.dimensionGroup = mod.dimensionGroup;
      this.options.dimension = mod.dimension;
      this.options.base = mod.base;
    }
  }
};
</script>

<style lang="scss">
.dom-container .node-template {
  background-color: white;
}
</style>
