<template>
  <b-tabs
    id="box-tabs"
    pills
    active-nav-item-class="nav-light-success"
    nav-class="dashboard-tabs row row-paddingless flex-column flex-sm-row m-0 p-0 nav-light-success"
    nav-wrapper-class="card-custom gutter-b"
    class="rounded shadow-sm my-6"
  >
    <b-tab
      :id="tab.id"
      no-body
      title-item-class="d-flex col-sm flex-glow-1 flex-shrink-0 mr-3 mb-lg-0"
      title-link-class="border-0 py-3 d-flex flex-grow-1 flex-column align-items-center"
      v-for="tab in tabs"
      :key="tab.id"
      :active="$route.hash === `#${tab.id}`"
      @click="$emit('changeTab', tab.id)"
    >
      <template slot="title">
        <span class="nav-icon py-0 w-auto">
          <span class="svg-icon svg-icon-4x svg-icon-success" v-if="tab.image">
            <simple-svg :src="require(`@/assets/images/icons/${tab.image}`)" />
          </span>
          <span class="icon-3x icon-success" v-else>
            <i :class="tab.icon"></i>
          </span>
        </span>
        <span class="nav-text font-size-lg pt-1 font-weight-bolder text-center">
          {{ tab.title }}
        </span>
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "BoxTabs",
  props: {
    tabs: {
      type: Array,
      required: true
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    "simple-svg": SimpleSVG
  }
};
</script>

<style lang="scss">
div#box-tabs {
  border-radius: 2rem !important;
  background-color: #ffffff;
  .card-custom.gutter-b {
    padding: 1rem;
    margin: 0%;
  }
  .nav.nav-pills.nav-light-success .nav-link.active {
    border-radius: 2rem;
  }
}
</style>
